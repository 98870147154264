const host = 'apexdigital.online';

export function sendEmail(name) {
    const mail = document.createElement('a');

    mail.style.display = 'none';
    mail.target = '_blank';
    mail.href = 'mailto:' + name + '@' + host;

    mail.click();
    mail.remove();
}
