import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { fadeIn } from "../variants.js";
import arrowDown from "../assets/svg/arrowDown.svg";
import useIsMobile from "../hook/useIsMobile.js";

export default function DiscoverOurRange() {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isArrowUpsideDown, setIsArrowUpsideDown] = useState(false);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const isMobile = useIsMobile();
  const containerRef = useRef(null);

  const categories = {
    All: [
      {
        name: "Hotel Opening Platform",
        description:
          "Efficiently guiding teams like operations and technical services, our platform streamlines hotel openings from budgeting to handover.",
        category: "Hospitality",
      },
      {
        name: "Project Management",
        description:
          "Streamline team collaboration for on-time, within-budget delivery of complex projects, with comprehensive monitoring capabilities.",
        category: "All Industries",
      },
      {
        name: "Dashboards / Business Intelligence Platform",
        description:
          "Our platform gathers data into a centralized warehouse, offering configurable charts and dashboards for insightful analysis and decision-making.",
        category: "All Industries",
      },
      {
        name: "Financial Planning & Tracking Platform",
        description:
          "Track and Forecast Income and Costs Across Projects, Including Travel, Resources, and more, get a live P&L for instant performance overview.",
        category: "All Industries",
      },
      {
        name: "Contract & Vendor Management",
        description:
          "Track and Forecast Income and Costs Across Projects, Including Travel, Resources, and more, get a live P&L for instant performance overview.",
        category: "All Industries",
      },
      {
        name: "Document Management",
        description:
          "ollaborate on complex documents with change tracking and approval management; dynamically generate tailored documents based on project specifics.",
        category: "All Industries",
      },
      {
        name: "Resource Management",
        description:
          "Optimize resource management for large teams: handle holidays, travel, appointments, deadlines, integrated into a clear scheduler for comprehensive oversight.",
        category: "All Industries",
      },
      {
        name: "Ticket Management",
        description:
          "Create ticket forms, collect and handle incoming requests collaboratively with your team. Implement escalation protocols, reports and more.",
        category: "All Industries",
      },
      {
        name: "Mass Litigation Platform",
        description:
          "Designed for lawyers, our platform efficiently manages mass litigation with document automation, AI analysis, and streamlined case management.",
        category: "Legal",
      },
      {
        name: "Tailored Software Solutions",
        description:
          "Customizing to fit your unique needs or creating from scratch. Explore our extensive range of pre-built software – contact us to discover more.",
        category: `Tailored\u00a0Software\u00a0Solution`,
      },
    ],
    Hospitality: [
      {
        name: "Hotel Opening Platform",
        description:
          "Efficiently guiding teams like operations and technical services, our platform streamlines hotel openings from budgeting to handover.",
        category: "Hospitality",
      },
    ],
    Legal: [
      {
        name: "Mass Litigation Platform",
        description:
          "Designed for lawyers, our platform efficiently manages mass litigation with document automation, AI analysis, and streamlined case management.",
        category: "Legal",
      },
    ],
    TailoredSoftwareSolutions: [
      {
        name: "Tailored Software Solutions",
        description:
          "Customizing to fit your unique needs or creating from scratch. Explore our extensive range of pre-built software – contact us to discover more.",
        category: `Tailored\u00a0Software\u00a0Solution`,
      },
    ],
  };

  const subcategories = selectedCategory ? categories[selectedCategory] : [];
  const visibleSubcategories = showAllCategories
    ? subcategories
    : subcategories.slice(0, 8);

  const handleShowMoreClick = () => {
    setShowAllCategories((prev) => !prev);
    setIsArrowUpsideDown((prev) => !prev);

    if (showAllCategories && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    setSelectedCategory("All");
  }, []);

  return (
    <motion.div
      id="software_solutions"
      className={`${
        isMobile
          ? "bg-[#141414] pt-[3rem] pb-[3rem]"
          : "bg-[#141414] md:pt-[9rem] md:pb-[9rem]"
      } `}
      animate={{ height: "auto" }}
      ref={containerRef}
    >
      <div className="sm:container sm:mx-auto">
        <motion.h2
          variants={fadeIn("up", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className={`${
            isMobile
              ? "text-title-section-mobile md:text-6xl lg:text-8xl md:flex md:justify-center z-20 pb-8 md:pb-20"
              : "md:text-58 md:text-6xl lg:text-8xl md:flex md:justify-center z-20 pb-8 md:pb-20"
          }`}
        >
          <p className="text-center text-white">
            Discover Our Range of <br />
            <span className="text-brand">Ready-to-Deploy </span>
            <br />
            Software Solutions
          </p>
        </motion.h2>
        <div
          className={`container mx-auto flex flex-wrap justify-center md:gap-4 md:pb-30 ${
            isMobile ? "px-3 pb-14 gap-2" : ""
          }`}
        >
          {Object.keys(categories).map((category, index) => {
            const isSelected = selectedCategory === category;
            const textColor = isSelected ? "text-white" : "text-[#787777]";
            const displayCategory =
              category === "TailoredSoftwareSolutions"
                ? "Tailored Software Solutions"
                : category;
            return (
              <div
                key={index}
                className={`cursor-pointer md:text-category border border-solid border-1 border-gray-800 md:pt-2.5 md:pb-2.5 md:pr-9 md:pl-9 md:mb-4 bg-gray-900 shadow-md ${
                  isMobile ? "pr-4 pl-4 pt-0.5 pb-0.5 text-category-mobile" : ""
                } ${textColor} }`}
                onClick={() => {
                  setSelectedCategory(category);
                }}
              >
                {displayCategory}
              </div>
            );
          })}
        </div>
        {visibleSubcategories.length > 0 && (
          <div
            className={`flex flex-wrap justify-center gap-4 ${
              isMobile ? "px-4" : ""
            }`}
          >
            {visibleSubcategories.map((subcategory, index) => (
              <div
                key={index}
                className="cursor-pointer overflow-hidden relative border border-solid border-1 border-[#2C2C2C] pt-12 pr-6 pl-6 pb-5  bg-gray-900 shadow-md xl:max-w-[245px] 2xl:max-w-[296px]"
              >
                {" "}
                {subcategory.category === "Tailored Software Solution" ? (
                  <span className="uppercase text-white text-label rounded-tl-none rounded-tr-none rounded-br-none rounded-bl-[1rem] bg-brand p-2 px-5 lg:px-2 text-center absolute top-0 right-0">
                    {subcategory.category}
                  </span>
                ) : (
                  <span className="uppercase text-white text-label rounded-tl-none rounded-tr-none rounded-br-none rounded-bl-[1rem] bg-brand p-2 px-5 text-center absolute top-0 right-0">
                    {subcategory.category}
                  </span>
                )}
                <div
                  className={`${
                    isMobile
                      ? "text-white text-title-block-desktop"
                      : "text-white text-title-block-desktop"
                  }`}
                >
                  {subcategory.name}
                </div>
                <div
                  className={`text-white ${
                    isMobile
                      ? "pt-2 text-mobile-base"
                      : "pt-2 text-block-desktop"
                  }`}
                >
                  {subcategory.description}
                </div>
              </div>
            ))}
          </div>
        )}
        {visibleSubcategories.length > 1 && (
          <div className="flex justify-center md:mt-20 mt-6">
            {" "}
            <div className="flex justify-center">
              <button
                onClick={handleShowMoreClick}
                className={`flex items-center shrink-0 md:flex-none md:py-4 py-3 md:py-4 pl-3.5 pr-2 md:px-8 md:px-9 md:my-16 lg:my-0 w-full md:w-fit bg-brand hover:bg-brand-hover transition-colors text-white text-20 md:text-standard ${
                  isMobile ? "mb-16" : ""
                }`}
              >
                <span>
                  {showAllCategories
                    ? "Show Less"
                    : "Discover more pre-built Apex software"}
                </span>
                <motion.img
                  src={arrowDown}
                  alt="arrowDown"
                  className={`ml-4 transform ${
                    isArrowUpsideDown ? "rotate-180" : ""
                  }`}
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </motion.div>
  );
}
