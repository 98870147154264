import React from "react";

export const MovingIcons = ({ baseImage, iconOne, iconTwo, mousePosition }) => {
  const { x } = mousePosition;

  return (
    <div className="w-40 md:w-36 relative">
      <img className="" src={baseImage} alt="icon" />
      <img
        className="absolute top-0 right-0"
        src={iconTwo}
        alt="icon"
        style={{
          transform: `translateX(${-x / 80}px)`,
        }}
      />
      <img
        className="absolute top-0 right-0"
        src={iconOne}
        alt="icon"
        style={{
          transform: `translateX(${x / 80}px)`,
        }}
      />
    </div>
  );
};
