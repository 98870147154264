import React from "react";
import { Footer } from "./Footer";

import officeBuilding from "../assets/images/office-building.jpg";
import { sendEmail } from "../sendEmail";
import useIsMobile from "../hook/useIsMobile";

export const Contacts = ({ openModal }) => {
  const isMobile = useIsMobile();

  return (
    <div
      id="contacts"
      className="container mx-auto flex flex-col-reverse md:flex-row md:gap-16 lg:gap-32 md:mb-8"
    >
      <div className="w-full md:w-1/2">
        <img
          className="w-full h-full object-cover object-center h-auto px-4 md:px-0"
          src={officeBuilding}
          alt="Office"
        />
      </div>
      <div className="w-full md:w-1/2 flex-1 contacts pt-8 md:pt-0 px-4 md:px-0 md:flex md:flex-col md:w-6/12">
        <h4 className="text-xl sm:text-base md:text-3xl text-title">
          Headquarter
        </h4>
        <p className="text-xl sm:text-base md:text-3xl text-grey-500">
          Apex Digital GmbH
          <br />
          Überseeallee 10
          <br />
          20457 Hamburg Germany
        </p>

        <h4 className="text-xl sm:text-base md:text-3xl text-title mt-10">
          US Office
        </h4>
        <p className="text-xl sm:text-base md:text-3xl text-grey-500">
          413 West 14th Street #200
          <br />
          New York City, NY 10014
        </p>

        {/*<a className="text-xl sm:text-base md:text-3xl text-brand" href="mailto:hello@apexdigital.online">hello@apexdigital.online</a>*/}

        {/*<h4 className="text-xl sm:text-base sm:text-base md:text-3xl text-title mt-10">US Office</h4>*/}
        {/*<p className="text-xl sm:text-base md:text-3xl text-grey-500 mb-10">*/}
        {/*  14150 Chaparral Lane<br />*/}
        {/*  Roanoke TX 76262 USA*/}
        {/*</p>*/}

        <h4 className="text-xl sm:text-base md:text-3xl text-title mt-10">
          Contact
        </h4>
        <p className="text-xl sm:text-base md:text-3xl text-grey-500">
          Dr. Bernhard Schulz
        </p>
        <a
          className="text-xl sm:text-base md:text-3xl text-brand mb-10"
          href="#"
          onClick={(event) => {
            event.preventDefault();

            sendEmail("bschulz");
          }}
        >
          bschulz [at] apexdigital.online
        </a>

        <button
          onClick={openModal}
          className="shrink-0 md:flex-none py-4 md:py-4 px-8 md:px-12 my-16 lg:my-0 w-full md:w-fit bg-brand hover:bg-brand-hover transition-colors text-white text-20 md:text-standard"
        >
          Contact Us
        </button>

        <div className="flex-1 grow" />
        {!isMobile ? <Footer /> : null}
      </div>
    </div>
  );
};
