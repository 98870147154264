import { useState, useEffect } from 'react';

/**
 * Custom hook to determine if the current device is mobile based on window width.
 *
 * @returns {boolean} True if the device is mobile (width < 768px), otherwise false.
 */
const useIsMobile = () => {
  // Set initial state based on current window width
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    // Function to update state based on window width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;
