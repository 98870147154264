import React from "react";
import useIsMobile from "../hook/useIsMobile";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";

import apexLogoBig from "../assets/svg/big-apex-logo.svg";
import MapWithTooltips from "../components/MapWithTooltips";

export const Projects = () => {
  const isMobile = useIsMobile();
  return (
    <div className="container mx-auto relative flex flex-col items-center justify-center py-20 md:pt-25 md:pb-50">
      <motion.h2
        variants={fadeIn("up", 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true, amount: 0.7 }}
        className={`w-full text-title px-4 md:text-6xl lg:text-8xl text-center md:w-2/4 ${
          isMobile ? "text-58-mobile mb-6" : "text-58 mb-10"
        }`}
      >
        Organizations streamlined with
      </motion.h2>
      <motion.img
        variants={fadeIn("up", 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true, amount: 0.7 }}
        className="md:mb-32 mb-20 w-2/4 md:w-auto"
        src={apexLogoBig}
        alt="Apex Logo Big"
      />
      <MapWithTooltips />
      <div className="absolute md:top-[370px] hidden md:block left-0 border-t-2 w-4/12 border-brand"></div>
      <div className="absolute md:top-[370px] hidden md:block right-0 border-t-2 w-4/12 border-brand"></div>
    </div>
  );
};
