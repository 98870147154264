import React, {useEffect, useState} from 'react';
import axios from 'axios';
import chatIcon from '../assets/images/chat-icon.png';
import {ReactComponent as IconTimesDark} from '../assets/svg/timesDark.svg';
import {ReactComponent as IconTimesLight} from '../assets/svg/timesLight.svg';
import {FormInput} from '../components';

export const Modal = ({closeModal, openSentModal}) => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
    });

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
    });

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isFormSent, setIsFormSent] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const validateForm = () => {
        const errors = {};

        if (!formData.firstName) {
            errors.firstName = 'First name is required';
        } else {
            errors.firstName = '';
        }

        if (!formData.lastName) {
            errors.lastName = 'Last name is required';
        } else {
            errors.lastName = '';
        }

        if (!formData.email) {
            errors.email = 'Email is required';
        } else if (!isValidEmail(formData.email)) {
            errors.email = 'Invalid email format';
        } else {
            errors.email = '';
        }

        if (!formData.message) {
            errors.message = 'Message is required';
        } else {
            errors.message = '';
        }

        setFormErrors(errors);

        return Object.values(errors).every(error => error === '');
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const isValid = validateForm();

        if (isValid) {
            setIsFormSent(true);

            const {firstName, lastName, ...rest} = formData;

            const sendData = {
                first_name: firstName,
                last_name: lastName,
                ...rest
            };


            if (!isChecked) {
                axios.post('https://mt-emea-oasis.apexdigital.online/api/v1/contact', sendData)
                    .then(response => {
                        openSentModal();
                        setIsFormSent(false);
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                console.log('Sorry');
            }
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (formErrors[name]) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
        }
    };


    const isValidEmail = (value) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/;
        return emailPattern.test(value);
    };


    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50 p-4 md:p-0">
            <div
                className="max-w-7xl md:max-h-full h-full md:h-auto w-full bg-brand text-white p-8 relative overflow-y-scroll md:overflow-auto">
                {!isMobile || (
                    <button onClick={closeModal} className="absolute top-2 right-4">
                        <IconTimesLight/>
                    </button>
                )}
                <div className="flex flex-col h-full lg:flex-row gap-2 sm:gap-8">
                    <div className="flex flex-col justify-center items-center md:px-20 text-center">
                        <img className="w-24 md:w-48 mb-4 md:mb-20" src={chatIcon} alt="Email Icon"/>
                        <h2 className="text-5xl md:text-8xl mb-5">Contact Us</h2>
                        <p className="text-20 md:text-2xl">
                            Fill in your information and we'll be in touch as soon as we can
                        </p>
                    </div>
                    <div
                        className="flex flex-col text-black relative">
                        {isMobile || (
                            <button onClick={closeModal} className="absolute top-2 right-4">
                                <IconTimesDark/>
                            </button>
                        )}
                        <form onSubmit={handleSubmit}
                              className="flex flex-col bg-white p-4 sm:p-4 md:p-8 gap-2 sm:gap-4 md:gap-8 md:pt-32">
                            <div className="flex flex-col lg:flex-row md:gap-8">
                                <input
                                    type="checkbox"
                                    onChange={handleCheckboxChange}
                                    checked={isChecked}
                                    style={{display: 'none'}}
                                />
                                <FormInput
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="First Name"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    error={formErrors.firstName}
                                />
                                <FormInput
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    error={formErrors.lastName}
                                />
                            </div>
                            <FormInput
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                error={formErrors.email}
                            />
                            <FormInput
                                as="textarea"
                                id="message"
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                                error={formErrors.message}
                                rows={4}
                            />
                            <button type="submit"
                                    className={`button py-4 px-8 md:px-20 md:py-6 w-full text-20 md:text-xl md:w-auto md:self-end focus:outline-none ${isFormSent ? 'button--loading' : ''}`}>
                                <span className="button__text">Send</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
