import { useState, useEffect } from "react";

/**
 * Custom hook to determine if the current device is mobile based on window width.
 *
 * @returns {boolean} True if the device is mobile (width < 768px), otherwise false.
 */
const useIsTablet = () => {
  // Set initial state based on current window width
  const [isTable, setisTable] = useState(window.innerWidth < 1024);

  useEffect(() => {
    // Function to update state based on window width
    const handleResize = () => {
      setisTable(window.innerWidth < 1024);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isTable;
};

export default useIsTablet;
