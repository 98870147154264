import React from 'react';
import styled from 'styled-components';

const ButtonComponent = styled.button.attrs({
    className: "px-12 py-6 w-full md:w-auto bg-brand text-white font-bold"
})`
`;

export const Button = ({type, variant, className, id, onClick, children}) => {
    return (
        <ButtonComponent
            type={type ? type : "button"}
            variant={variant}
            className={className ? `btn-component ${className}` : "btn-component"}
            id={id}
            onClick={onClick}
        >
            {children}
        </ButtonComponent>
    );
};
