import React from 'react';
import sentIcon from '../assets/images/sent-icon.png';
import timesIconLight from '../assets/svg/timesLight.svg';

export const ModalSent = ({closeSentModal}) => {
    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50 p-4 md:p-0">
            <div className="max-w-7xl w-full h-full md:h-auto bg-brand text-white p-8 md:py-24 relative">
                <button onClick={closeSentModal} className="absolute top-2 right-4">
                    <img src={timesIconLight} alt="close"/>
                </button>
                <div className="flex flex-col justify-center items-center gap-8 h-full grow">
                    <div className="flex flex-col w-full justify-center items-center md:px-20 text-center">
                        <img className="w-52 mb-4 md:mb-20" src={sentIcon} alt="Sent Icon"/>
                        <h2 className="text-5xl md:text-8xl mb-5">Your message sent</h2>
                        <p className="text-20 md:text-3xl">We'll be in touch as soon as we can</p>
                    </div>
                    <button
                        onClick={closeSentModal}
                        className="bg-brand border border-white text-white text-20 py-4 px-8"
                    >
                        Go Back
                    </button>
                </div>
            </div>
        </div>
    );
};
