import React from "react";

import { motion } from "framer-motion";
import { fadeIn } from "../variants.js";

import bProcessMobile from "../assets/images/businessMobileNew.jpg";
import bigDataMobile from "../assets/images/bigDataMobileNew2.jpg";
import bigDataTablet from "../assets/images/bigDataTablet.jpg";
import bProcess from "../assets/svg/business2.svg";
import bProcessTablet from "../assets/images/business-tablet.jpg";
import bigDataSvg from "../assets/svg/big-data.svg";
import aI from "../assets/svg/ai2.svg";
import aiMobile from "../assets/images/aiMobile2.jpg";

import useIsMobile from "../hook/useIsMobile.js";
import useIsTablet from "../hook/useIsTablet.js";

export const EmpoweringYourBusiness = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  return (
    <div
      className={`container mx-auto ${
        isMobile ? "px-4" : "px-0"
      } pt-24 md:pt-32 pb-25 md:pb-32`}
      id="services"
    >
      <motion.h2
        variants={fadeIn("up", 0.3)}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: true, amount: 0.7 }}
        className={`container mx-auto md:text-6xl lg:text-8xl md:flex md:justify-center z-20 pb-8 md:pb-20 ${
          isMobile ? "text-left text-58-mobile" : "text-center text-58"
        }`}
      >
        <p>
          <span className="text-brand">Empowering Your Business:</span>{" "}
          <br></br>
          {isTablet ? (
            <span>
              {" "}
              Digitalization, Big&nbsp;Data, <br />
              and AI Solutions
            </span>
          ) : (
            "Digitalization, Big Data, and AI Solutions"
          )}
        </p>
      </motion.h2>
      <div className="flex flex-col container">
        <div className="bg-white mb-9 shadow-lg lg:flex lg:justify-between md:h-auto xl:h-[375px]">
          {isMobile && <img src={bProcessMobile} alt="Big Data" />}
          {isTablet && !isMobile && (
            <img src={bProcessTablet} alt="Big Data" className="md:w-full" />
          )}
          {!isMobile && !isTablet && (
            <div className="lg:w-6/12">
              <img src={bProcess} alt="Big Data" className="md:h-full" />
            </div>
          )}
          <div className="px-5 pb-5 lg:mt-8 lg:mt-0 lg:w-7/12 lg:max-w-[640px] lg:py-10 md:pr-10 md:flex md:justify-center md:items-center">
            <div className="lg:w-[640px] md:h-auto xl:h-[348px] md:flex md:flex-col md:justify-center">
              <p
                className={`text-desktop-title-main tracking-wide ${
                  isMobile ? "pt-10" : "pt-2"
                }`}
              >
                Digitalization and Automation of Business&nbsp;
                <span className="md:inline-block block">Processes</span>
              </p>
              <p
                className={`${
                  isMobile
                    ? "pt-8 text-mobile-base"
                    : "pt-5 pb-[1rem] text-base"
                }`}
              >
                Ever felt like you're losing track of your business
                transactions? We at Apex Digital empower businesses through
                digitalization and automation, turning complex workflows into
                streamlined operations. Our solutions equip you with essential
                tools for comprehensive oversight and management, enhancing
                visibility and control, especially in environments where data is
                scattered across multiple software platforms. We provide
                real-time access to vital data, enabling informed decisions and
                ensuring continuous control over all your business processes.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white mb-9 shadow-lg lg:flex lg:justify-between xl:h-[375px]">
          <div className="order-2 md:order-1 lg:w-6/12 md:flex md:justify-end">
            {isMobile && <img src={bigDataMobile} alt="Big Data" />}
            {isTablet && !isMobile && (
              <img src={bigDataTablet} alt="Big Data" className="md:w-full" />
            )}
            {!isMobile && !isTablet && (
              <img src={bigDataSvg} alt="Big Data" className="md:h-full" />
            )}
          </div>
          <div className="px-5 lg:mt-8 lg:mt-0 pb-5 lg:w-7/12 lg:max-w-[640px] md:py-10 md:pr-10 md:flex md:justify-center md:items-center lg:pl-10">
            <div className="lg:w-[640px] md:h-auto xl:h-[348px] md:flex md:flex-col md:justify-center">
              <p
                className={`text-desktop-title-main tracking-wide ${
                  isMobile ? "pt-10" : "pt-2"
                }`}
              >
                Business Intelligence and{" "}
                <span className="block">Big Data</span>
              </p>
              <p
                className={`${
                  isMobile
                    ? "pt-8 text-mobile-base"
                    : "pt-5 pb-[1rem] text-base"
                }`}
              >
                Our Business Intelligence and Big Data services transform data
                into insights. We gather information from across your
                organization, consolidating it into a centralized data
                warehouse. This approach enables us to create comprehensive
                dashboards that track KPIs, offering a clear overview of your
                business. Even in environments with diverse, non-communicative
                software systems, our solutions provide you with the tools to
                monitor and understand every aspect of your operation.
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white mb-9 shadow-lg lg:flex lg:justify-between xl:h-[375px]">
          <div className="lg:w-6/12 flex justify-center md:flex md:justify-start">
            {isMobile && <img src={aiMobile} alt="Big Data" />}
            {isTablet && !isMobile && (
              <img src={aI} alt="Big Data" className="md:w-full" />
            )}
            {!isMobile && !isTablet && <img src={aI} alt="Big Data" />}
          </div>
          <div className="px-5 pb-5 lg:mt-8 lg:mt-0 lg:w-7/12 md:max-w-[640px] md:py-10 md:pr-10 md:flex md:justify-center md:items-center">
            <div className="lg:w-[640px] md:h-auto xl:h-[348px] md:flex md:flex-col md:justify-center">
              <p
                className={`text-desktop-title-main tracking-wide ${
                  isMobile ? "pt-10" : "pt-2"
                }`}
              >
                Artificial Intelligence
              </p>
              <p
                className={`${
                  isMobile ? "pt-8 text-mobile-base" : "pt-5 text-base"
                }`}
              >
                Apex Digital enhances business processes with cutting-edge
                Artificial Intelligence. Our AI solutions are versatile,
                offering precision in various applications. Our AI solutions
                enhance tasks like deep document analysis, including contracts
                and payroll. We predict customer behavior and business risks,
                offering strategic insights. Additionally, we automate
                decision-making, allowing our AI to swiftly make informed
                choices, streamlining your operations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
