import React, { useState } from "react";
import { Home } from "./page/Home";
import { CaseStudy } from "./page/CaseStudy";
import { Routes, Route } from "react-router-dom";

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    setMousePosition({ x: event.clientX, y: event.clientY });
  };

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("disable-scroll");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("disable-scroll");
  };

  const openSentModal = () => {
    setIsSent(true);
    setIsModalOpen(false);
  };

  const closeSentModal = () => {
    setIsSent(false);
    document.body.classList.remove("disable-scroll");
  };

  return (
    <Routes>
      <Route
        element={
          <Home
            handleMouseMove={handleMouseMove}
            openModal={openModal}
            mousePosition={mousePosition}
            isModalOpen={isModalOpen}
            isSent={isSent}
            closeModal={closeModal}
            openSentModal={openSentModal}
            closeSentModal={closeSentModal}
          />
        }
        path="/"
      />
      {/* <Route
        element={
          <CaseStudy
            handleMouseMove={handleMouseMove}
            openModal={openModal}
            mousePosition={mousePosition}
            isModalOpen={isModalOpen}
            isSent={isSent}
            closeModal={closeModal}
            openSentModal={openSentModal}
            closeSentModal={closeSentModal}
          />
        }
        path="/case_study/:caseStudyName/:svgName"
      /> */}
    </Routes>
  );
};

export default App;
