import React, {useEffect, useRef} from "react";
import {motion, useAnimation, useInView} from "framer-motion";

export const Reveal = ({children, width = "fit-content"}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            void mainControls.start("visible");
        }
    }, [isInView, mainControls])

    return (
        <div ref={ref} className={`relative ${width} overflow-hidden`}>
            <motion.div
                variants={{
                    hidden: {opacity: 0, y: 80},
                    visible: {opacity: 1, y: 0},
                }}
                initial="hidden"
                animate={mainControls}
                transition={{duration: 0.5, delay: 0.25, ease: "easeInOut"}}
            >
                {children}
            </motion.div>
        </div>
    );
};
