import React, { useRef } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import { ReactComponent as IconBars } from "../assets/svg/bars2.svg";
import { ReactComponent as IconTimesDark } from "../assets/svg/timesDark.svg";
import { ReactComponent as Logo } from "../assets/svg/logo.svg";
import useIsMobile from "../hook/useIsMobile.js";
import useIsTablet from "../hook/useIsTablet.js";
import { useParams } from "react-router-dom";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const Header = ({ openModal }) => {
  const navRef = useRef();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { caseStudyName } = useParams();

  const showNavbar = () => {
    navRef.current.classList.toggle("translate-y-0");
    navRef.current.classList.toggle("translate-y-[-100%]");
  };

  return (
    <div className="container mx-auto mb-10">
      <div
        className={`flex items-center justify-between px-5 py-0 lg:py-5 lg:px-0 ${
          isTablet ? "md:px-0" : ""
        }`}
      >
        <div>
          {isMobile ? (
            <RouterLink to="/">
              <Logo width={102} />
            </RouterLink>
          ) : (
            <RouterLink to="/">
              <Logo width={147} />
            </RouterLink>
          )}
        </div>
        <nav
          className="fixed lg:static p-4 lg:p-0 top-0 left-0 z-50 h-full lg:h-min w-full flex flex-col lg:flex-row items-center justify-around lg:justify-between bg-white translate-y-[-100%] lg:translate-y-0 ease-in-out duration-300"
          ref={navRef}
        >
          <div className="flex flex-col lg:flex-row justify-center items-center w-full gap-12 lg:gap-12 xl:gap-24 grow">
            <div className="flex flex-col lg:flex-row justify-center items-center w-full lg:gap-12 xl:gap-24 lg:grow">
              {!caseStudyName ? (
                <>
                  <ScrollLink
                    to="services"
                    activeClass="active"
                    smooth={true}
                    spy={true}
                    className="cursor-pointer pb-22.5 lg:pb-0 text-mobile-title-main text-brand lg:text-black lg:text-base lg:font-medium"
                    onClick={showNavbar}
                  >
                    Services
                  </ScrollLink>
                  <ScrollLink
                    to="software_solutions"
                    activeClass="active"
                    smooth={true}
                    spy={true}
                    className="cursor-pointer pb-22.5 lg:pb-0 text-mobile-title-main text-brand lg:text-black lg:text-base lg:font-medium"
                    onClick={showNavbar}
                  >
                    Solutions
                  </ScrollLink>
                </>
              ) : (
                <>
                  <RouterLink
                    to="/"
                    className="cursor-pointer pb-22.5 md:pb-0 text-mobile-title-main text-brand md:text-black md:text-base md:font-medium"
                  >
                    Home
                  </RouterLink>
                  <ScrollLink
                    to="process"
                    activeClass="active"
                    smooth={true}
                    spy={true}
                    className="cursor-pointer pb-22.5 md:pb-0 text-mobile-title-main text-brand md:text-black md:text-base md:font-medium"
                    onClick={showNavbar}
                  >
                    Process
                  </ScrollLink>
                </>
              )}
              {!caseStudyName ? (
                <ScrollLink
                  to="contacts"
                  activeClass="active"
                  smooth={true}
                  spy={true}
                  // offset={isMobile ? -900 : (isSafari ? -710 : -650)}
                  className="cursor-pointer text-mobile-title-main text-brand lg:tracking-normal lg:text-black lg:text-base lg:font-medium"
                  onClick={showNavbar}
                >
                  Contacts
                </ScrollLink>
              ) : (
                <ScrollLink
                  to="project-present"
                  activeClass="active"
                  smooth={true}
                  spy={true}
                  className="cursor-pointer pb-22.5 lg:pb-0 text-mobile-title-main text-brand lg:tracking-normal lg:text-black lg:text-base lg:font-medium"
                  onClick={showNavbar}
                >
                  Project
                </ScrollLink>
              )}
            </div>
          </div>
          {isMobile && (
            <RouterLink to="/">
              <Logo width={82} className="absolute top-3 left-6" />
            </RouterLink>
          )}
          <button
            className="absolute top-6 right-4 pointer p-2 visible lg:hidden"
            onClick={showNavbar}
          >
            <IconTimesDark />
          </button>
          <button
            onClick={openModal}
            className="shrink-0 mx-4 lg:mx-0 lg:flex-none p-2 px-8 lg:px-12 my-16 lg:mt-4 mb-4 w-full lg:w-auto bg-brand hover:bg-brand-hover transition-colors text-white text-20 lg:text-base"
          >
            Contact Us
          </button>
        </nav>
        <button className="visible lg:hidden" onClick={showNavbar}>
          <IconBars />
        </button>
      </div>
    </div>
  );
};
