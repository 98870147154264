import React from 'react';

const FormInput = ({label, error, as, ...props}) => {
    const hasError = error !== '';
    const InputComponent = as === 'textarea' ? 'textarea' : 'input';

    return (
        <div className="mb-4">
            <InputComponent
                {...props}
                className={`border-b px-2 py-2 text-20 text-zinc-500 focus:outline-none mt-4 md:mt-0 md:mb-4 w-full ${
                    hasError ? 'border-b-red-500' : 'border-b-gray-300'
                }`}
            />
            {hasError && <span className="text-red-500 text-sm mt-1">{error}</span>}
        </div>
    );
};

export default FormInput;
