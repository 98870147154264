import React from "react";
import useIsMobile from "../hook/useIsMobile.js";
import { ImageWithSign } from "../components";

import { motion } from "framer-motion";
import { fadeIn } from "../variants.js";

// import peterImage from '../assets/images/peter.png';
import bernhardImage from "../assets/images/bernhard.png";

// import peterSignature from '../assets/images/peters-signature.png';
import bernhardSignature from "../assets/images/bernhards-signature.png";

export const Message = () => {
  const isMobile = useIsMobile();
  return (
    <div
      className={`bg-brand py-10  md:py-56 ${isMobile ? "py-12" : "md:py-20"}`}
      id="message"
    >
      <div className="container mx-auto">
        <motion.h2
          variants={fadeIn("up", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className={`md:w-full text-title md:text-6xl lg:text-8xl text-white  px-4 md:px-0  ${
            isMobile
              ? "text-center mb-12 text-58-mobile"
              : "text-left md:mb-20 text-58"
          }`}
        >
          Founders
          <br className="block md:hidden" /> Message
        </motion.h2>
        <div className="flex flex-col md:flex-row mx-4">
          {/*<div className="flex-1 grow shrink-0 flex flex-row mb-8">*/}
          <div className="flex-1 grow shrink-0 flex flex-row mb-8 justify-center">
            {/*<ImageWithSign*/}
            {/*  className="translate-x-[10px] lg:translate-x-[20px]"*/}
            {/*  name="Peter Chambers"*/}
            {/*  image={peterImage}*/}
            {/*  sign={peterSignature}*/}
            {/*/>*/}
            <ImageWithSign
              // className="translate-x-[-10px] lg:translate-x-[-20px] pt-28"
              className="pt-3"
              name="Dr. Bernhard Schulz"
              image={bernhardImage}
              sign={bernhardSignature}
            />
          </div>
          <div className="flex-1 px-4 md:pl-16 text-20 text-white">
            <p className="relative">
              <span style={{ marginBottom: "10px", display: "block" }}>
                <span className="text-h3">&ldquo;</span>
                We stand out by delivering immediate value with ready-made
                software solutions and extensive expertise in business process
                structuring across various industries.
              </span>
              <span style={{ marginBottom: "10px", display: "block" }}>
                Our immersive philosophy involves closely integrating with
                customer teams, stepping into their shoes to gain an insider's
                perspective and deeply understand their unique challenges and
                needs.
              </span>
              <span style={{ marginBottom: "10px", display: "block" }}>
                At Apex Digital, we believe that achieving outstanding results
                hinges on a blend of dedication, professional excellence, and a
                consultative mindset. This is the commitment and quality that
                Apex Digital embodies.
              </span>
            </p>
            <hr className="my-6" />
            <p>Apex [noun]</p>
            <ol className="list-decimal pl-16">
              <li>The highest point of achievement</li>
              <li>A climax</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};
