import React from "react";
import useIsMobile from "../hook/useIsMobile.js";
import { Banner } from "./Banner";

import { ReactComponent as Divider01 } from "../assets/svg/divider-01.svg";
import { ReactComponent as ApexLogo } from "../assets/svg/apex.svg";

import { motion } from "framer-motion";
import { fadeIn } from "../variants.js";
import { Reveal } from "../components/Reveal";

export const Hero = ({ openModal, mousePosition }) => {
  const { x, y } = mousePosition;
  const isMobile = useIsMobile();
  return (
    <div className="md:relative">
      <div className="md:absolute z-10 md:top-0 w-full">
        <div className="container mx-auto">
          <div className="px-4 md:px-0 flex flex-col md:items-start">
            <Reveal>
              <h1
                className={`md:w-3/4 md:max-w-6xl  md:text-6xl lg:text-8xl text-left md:text-8xl mb-8 md:mb-4 lg:mb-8 ${
                  isMobile ? "text-58-mobile" : "text-58"
                }`}
              >
                <span className="text-brand">Transforming</span> Organizations
                with <span className="text-brand">Digital</span> Innovation
              </h1>
            </Reveal>

            <motion.h2
              variants={fadeIn("up", 0.5)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="text-20 md:text-22 text-left md:text-left md:leading-loose mb-14 sm:mb-8 md:mb-2 lg:mb-12 md:w-[67%] lg:w-2/4"
            >
              <span className="mr-[-8px] md:mr-1.5">
                Innovate, Transform, Excel: Reach the
              </span>
              <span className="inline-block relative top-[5px] md:top-[3px]">
                <ApexLogo width={72} className="scale-[.65] md:scale-100" />
                <div className="absolute right-[-30px] top-[12px] md:top-[22px] md:right-[-16px]">
                  <Divider01
                    width={164}
                    className="scale-[.65] md:scale-[.96] md:transform md:-translate-y-2"
                  />
                </div>
              </span>
              <span className="inline-block md:inline md:ml-1.5">
                of Digital Transformation with Us for Lasting Business Impact
              </span>
            </motion.h2>
            <motion.button
              variants={fadeIn("up", 0.7)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              onClick={openModal}
              className="bg-brand hover:bg-brand-hover transition-colors py-4 md:py-6 px-8 md:px-12 font-normal text-white text-20 md:text-2xl"
            >
              Contact Us
            </motion.button>
          </div>
        </div>
      </div>
      <div className="flex flex-col overflow-hidden">
        <div className="flex md:flex-row">
          <div className="md:shrink-0 basis-1/4 md:basis-1/2" />
          <svg
            className="relative shrink-0 md:h-[585px] translate-y-16 md:translate-x-0 h-full"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 944 766"
          >
            <circle cx="446.5" cy="429.5" r="326" fill="#452BDA" />
            <path d="M416,0.5L0,638l309.5,77L416,0.5z" fill="#DBE1EB" />
            <path
              d="M416,0.5L309.5,715L534,694l63.5-413L416,0.5z"
              fill="#B8BFCC"
            />
            <path
              d="M597.5,281.5L534,694l29.5,7.5L640,227L597.5,281.5z"
              fill="#DBE1EB"
            />
            <path d="M640,226.5l-76.5,475l380-31.5L640,226.5z" fill="#B8BFCC" />
            <g transform={`translate(${x / 5 - 150}, ${y / 20})`}>
              <polygon
                points="473.1,431.2 459.9,431.2 436.1,344.4 	"
                fill="#A6AEBB"
              />
              <polygon
                points="473.2,431.5 436.3,521 436.3,521 460.2,432.2 459.9,431.2 473.1,431.2 	"
                fill="#808894"
              />
              <polygon
                points="459.9,431.2 397.2,431.2 434.8,341.4 436.1,344.4 	"
                fill="#E4EDFC"
              />
              <polygon
                points="460.2,432.2 436.3,521 397,431.5 397.2,431.2 459.9,431.2 	"
                fill="#DEE7F5"
              />
            </g>
            <g transform={`translate(${x / 24}, ${y / 24})`}>
              <polygon
                points="627.8,72.8 603.7,120.9 603.6,120.6 603.6,72.8 	"
                fill="#A6ABB6"
              />
              <polygon
                points="628,72.2 627.8,72.8 603.6,72.8 603.6,25 603.7,24.7 	"
                fill="#C0C5CF"
              />
              <polygon
                points="603.6,72.8 603.6,120.6 579.4,72.8 	"
                fill="#E0E6F1"
              />
              <polygon
                points="603.6,25 603.6,72.8 579.4,72.8 	"
                fill="#E5EBF6"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="md:absolute bottom-0 md:bottom-[-22vh] lg:bottom-[-10vh] w-full h-fit">
        <div className="container mx-auto">
          <div className="bg-white p-8 md:py-8 lg:py-14 md:px-20 shadow-lg z-50">
            <Banner />
          </div>
        </div>
      </div>
    </div>
  );
};
