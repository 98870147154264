import React, { useEffect, useState } from "react";
import {
  ApexDifference,
  Contacts,
  Footer,
  Header,
  Hero,
  Message,
  OurCaseStudies,
  Projects,
  EmpoweringYourBusiness,
  Stats,
} from "../sections";
import { Modal, ModalSent } from "../components";
import { Loader } from "../components/Loader";
import useIsMobile from "../hook/useIsMobile";
import DiscoverOurRange from "../sections/DiscoverOurRange";

export const Home = ({
  handleMouseMove,
  openModal,
  mousePosition,
  isModalOpen,
  isSent,
  closeModal,
  openSentModal,
  closeSentModal,
}) => {
  const isMobile = useIsMobile();

  const [isComponentMounted, setComponentMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 2000);
      });
      setIsLoading(false);
      setComponentMounted(true);
    };
    loadData();
  }, []);

  if (!isComponentMounted) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div onMouseMove={handleMouseMove}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Header openModal={openModal} />
          <Hero openModal={openModal} mousePosition={mousePosition} />
          <Stats />
          {/* <Advantages mousePosition={mousePosition}/> */}
          <ApexDifference mousePosition={mousePosition} />
          {/* <Solutions openModal={openModal}/> */}
          <EmpoweringYourBusiness />
          <DiscoverOurRange />
          <Message />
          {/* <OurCaseStudies /> */}
          <Projects />
          <Contacts openModal={openModal} isMobile={isMobile} />

          {isMobile && <Footer />}
          {isModalOpen && (
            <Modal closeModal={closeModal} openSentModal={openSentModal} />
          )}
          {isSent && <ModalSent closeSentModal={closeSentModal} />}
        </>
      )}
    </div>
  );
};
