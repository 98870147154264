import React from "react";

import { motion } from "framer-motion";
import { fadeIn } from "../variants.js";

import { MovingIcons } from "../components";
import readyMade from "../assets/images/readyMade.png";
import proven from "../assets/images/proven.png";
import integration from "../assets/images/integration.png";
import middleCube from "../assets/images/middle-cube.png";
import leftCubes from "../assets/images/left-cubes.png";
import useIsMobile from "../hook/useIsMobile.js";
import useIsTablet from "../hook/useIsTablet.js";

export const ApexDifference = ({ mousePosition }) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  return (
    <div className="relative w-full h-full mx-auto p-0" id="apex_difference">
      <div className="flex md:justify-center flex-col lg:flex-row justify-start pt-20 px-4 pb-6  bg-brand lg:h-[550px]">
        {" "}
        <motion.h2
          variants={fadeIn("up", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className={`text-58 md:text-6xl lg:text-8xl block md:px-0 z-20 mb-10 text-white ${
            isTablet && !isMobile ? "md:m-auto md:mb-20" : ""
          } ${isMobile ? "sm:m-auto sm:mb-20 text-58-mobile" : ""}`}
        >
          The Apex <span className="block md:inline-block">Difference</span>
        </motion.h2>
        {isMobile && (
          <div className="container m-auto">
            <div>
              <div className="py-10 px-6 bg-white mb-4 shadow-lg">
                <MovingIcons
                  mousePosition={mousePosition}
                  baseImage={readyMade}
                  iconOne={leftCubes}
                  iconTwo={middleCube}
                />
                <p className="pt-6 text-desktop-title-main">Customized</p>
                <span className="pt-6 text-desktop-title-main">
                  Ready-Made Solutions:
                </span>
                <p
                  className={`pt-6 ${
                    isMobile ? " text-mobile-base" : " text-base"
                  }`}
                >
                  Choose Apex Digital for instant access to a suite of
                  ready-made, customizable tools, designed to seamlessly
                  integrate with your existing systems. Our solutions are built
                  to accelerate your digital transformation journey, ensuring
                  rapid deployment and immediate efficiency gains.
                </p>
              </div>
              <div className="py-10 px-6 bg-white mb-4 shadow-lg">
                <MovingIcons
                  mousePosition={mousePosition}
                  baseImage={proven}
                  iconOne={leftCubes}
                  iconTwo={middleCube}
                />
                <p className="pt-6 text-desktop-title-main">
                  Proven Experience in
                </p>
                <span className="pt-6 text-desktop-title-main">
                  Organizational
                </span>
                <br />
                <span className="pt-6 text-desktop-title-main">
                  Transformation:
                </span>
                <p
                  className={`pt-6 ${
                    isMobile ? " text-mobile-base" : " text-base"
                  }`}
                >
                  With a wealth of experience in transforming large
                  organizations, Apex Digital brings a track record of success.
                  Our expertise spans across various industries, ensuring that
                  your digital transformation is guided by knowledgeable and
                  seasoned professionals.
                </p>
              </div>
              <div className="py-10 px-6 bg-white shadow-lg">
                <MovingIcons
                  mousePosition={mousePosition}
                  baseImage={integration}
                  iconOne={leftCubes}
                  iconTwo={middleCube}
                />
                <p className="pt-6 text-desktop-title-main">Deep Integration</p>
                <span className="pt-6 text-desktop-title-main">
                  and Understanding:
                </span>
                <p
                  className={`pt-6 ${
                    isMobile ? " text-mobile-base" : " text-base"
                  }`}
                >
                  Our distinctive approach involves deep integration into your
                  organization with an immersive strategy, allowing us to
                  understand challenges and opportunities from within, ensuring
                  solutions are both effective and aligned with your unique
                  culture and goals.{" "}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={`${isTablet ? "md:bg-brand md:pb-[7rem]" : ""}`}>
        {" "}
        {!isMobile && (
          <div className="flex md:justify-center md:items-center container m-auto md:flex-col lg:flex-row">
            <div
              className={`md:w-100 lg:w-4/12 py-7.5 lg:px-7.5 md:px-7.5 bg-white mr-5 lg:mt-[-380px] shadow-lg ${
                isTablet ? "md:mb-10 md:mr-0" : ""
              }`}
            >
              <MovingIcons
                mousePosition={mousePosition}
                baseImage={readyMade}
                iconOne={leftCubes}
                iconTwo={middleCube}
              />
              <p className="md:pt-6 text-desktop-title-main">
                Customized
                <span className="block">Ready-Made Solutions:</span>
              </p>
              <p className="pt-6 text-base tracking-wide">
                Choose Apex Digital for instant access to a suite of ready-made,
                customizable tools, designed to seamlessly integrate with your
                existing systems. Our solutions are built to accelerate your
                digital transformation journey, ensuring rapid deployment and
                immediate efficiency gains.
              </p>
            </div>
            <div
              className={`md:w-100 lg:w-4/12 lg:max-w-[450px] py-7.5 lg:px-7.5 md:px-7.5 px-7.5 bg-white lg:mt-[-450px] mr-5 shadow-lg ${
                isTablet ? "md:mb-10 md:mr-0" : ""
              }`}
            >
              <MovingIcons
                mousePosition={mousePosition}
                baseImage={proven}
                iconOne={leftCubes}
                iconTwo={middleCube}
              />
              <p className="pt-6 text-desktop-title-main">
                Proven Experience in Organizational Transformation:
              </p>
              <p className="pt-6 text-base tracking-wide">
                With a wealth of experience in transforming large organizations,
                Apex Digital brings a track record of success. Our expertise
                spans across various industries, ensuring that your digital
                transformation is guided by knowledgeable and seasoned
                professionals.
              </p>
            </div>
            <div className="md:w-100 lg:w-4/12 py-7.5 lg:px-7.5 md:px-7.5 bg-white lg:mt-[-264px] xl:mt-[-325px] 2xl:mt-[-294px] shadow-lg">
              <MovingIcons
                mousePosition={mousePosition}
                baseImage={integration}
                iconOne={leftCubes}
                iconTwo={middleCube}
              />
              <p className="md:pt-6 text-desktop-title-main">
                Deep Integration
                <span className="block">and Understanding:</span>
              </p>
              <p className="pt-6 text-base tracking-wide">
                Our approach sets us apart. We don't just work from the
                sidelines; we integrate deeply into your organization. This
                immersive strategy allows us to understand your challenges and
                opportunities from the inside, ensuring solutions that are not
                only effective but also perfectly aligned with your unique
                organizational culture and goals.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
