import React from 'react';
import {ReactComponent as IconTimesDark} from "../assets/svg/timesDark.svg";

export const MessageBox = ({isOpen, onClose, children}) => {

    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center z-50 p-4 md:p-0">
            <div className="max-w-prose w-full bg-white text-black p-5 pt-12 md:pt-8 relative">
                <button onClick={onClose} className="absolute top-2 right-4">
                    <IconTimesDark/>
                </button>
                <div className="flex flex-col justify-start bg-white p-4 md:p-8 text-black md:h-auto">
                    {children}
                </div>
            </div>
        </div>
    );
};
