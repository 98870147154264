import React, { useState } from "react";
import { MessageBox } from "../components";
import { sendEmail } from "../sendEmail";

export const Footer = ({ className }) => {
  const [isMessageBoxOpen, setIsMessageBoxOpen] = useState(false);

  const containerClasses = `flex flex-wrap flex-col md:flex-row text-center md:text-left py-4 md:py-0 grow-0 md:w-full sm:items-center lg:flex-row lg:justify-between ${
    className || ""
  }`;
  const imprintClasses = "text-base text-brand mt-8 md:mt-0";
  const copyrightClasses = "text-base text-grey-500";

  const handleMessageBoxToggle = () => {
    setIsMessageBoxOpen(!isMessageBoxOpen);
  };

  return (
    <>
      <footer className={containerClasses}>
        <button className={imprintClasses} onClick={handleMessageBoxToggle}>
          Imprint
        </button>
        <p className={copyrightClasses}>
          &copy; 2020-2024 Apex Digital. All rights reserved.
        </p>
      </footer>

      <MessageBox isOpen={isMessageBoxOpen} onClose={handleMessageBoxToggle}>
        <h1 className="text-2xl font-bold mb-4">Imprint</h1>
        <p className="mb-4">
          <span className="font-bold">Apex Digital GmbH</span>
          <br />
          Überseeallee 10
          <br />
          20457 Hamburg
          <br />
          Germany
          <br />
          Registered in the Commercial Register of the Local Court of Hamburg,
          HRB 142473
        </p>
        <p>
          <span className="font-bold">Managing Director</span>: Dr. Bernhard
          Schulz
        </p>
        <a
          className="text-brand mt-4"
          href="#"
          onClick={(event) => {
            event.preventDefault();

            sendEmail("administration");
          }}
        >
          administration [at] apexdigital.online
        </a>
      </MessageBox>
    </>
  );
};
