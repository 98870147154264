import React from "react";
import CountUp from "react-countup";
import { ReactComponent as Divider02 } from "../assets/svg/divider-02.svg";
import { ReactComponent as Divider03 } from "../assets/svg/divider-03.svg";
import { ReactComponent as Divider04 } from "../assets/svg/divider-04.svg";

export const Stats = () => {
  return (
    <div className="relative">
      <div className="container relative mx-auto py-24 md:pb-56 lg:pt-72 md:pt-96">
        <div className="stats flex flex-col md:flex-row justify-between text-center md:items-start md:mx-3 lg:mx-12">
          <div className="flex flex-col items-center justify-end text-center mb-20">
            <h2 className="text-7xl text-title md:text-8xl lg:text-9xl tracking-tighter mb-3 md:mb-5 whitespace-nowrap">
              <CountUp start={0} end={25} duration={3} /> +
            </h2>
            <Divider02 className="md:h-6" />
            <h3 className="text-22 mt-3 md:mt-5">Curated Experts</h3>
          </div>
          <div className="flex flex-col items-center justify-end text-center mb-20">
            <h2 className="text-7xl text-title md:text-8xl lg:text-9xl tracking-tighter mb-3 md:mb-5 whitespace-nowrap">
              <CountUp start={0} end={1500} duration={3} separator=" " /> +
            </h2>
            <Divider03 className="md:h-6" />
            <h3 className="text-22 mt-3 md:mt-5">
              Organization streamlined with Apex Software
            </h3>
          </div>
          <div className="flex flex-col items-center justify-end text-center md:mb-20">
            <h2 className="text-7xl text-title md:text-8xl lg:text-9xl tracking-tighter mb-3 md:mb-5 whitespace-nowrap">
              <CountUp start={0} end={10} duration={3} /> +
            </h2>
            <Divider04 className="md:h-6" />
            <h3 className="text-22 mt-3 md:mt-5">Years of experience</h3>
          </div>
        </div>
      </div>
    </div>
  );
};
