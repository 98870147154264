import React from "react";
import { ReactComponent as MarriottLogo } from "../assets/svg/marriott-international-logo.svg";

export const Banner = ({ className }) => {
  // Class names
  const containerClasses = className
    ? `flex items-center justify-center gap-10 bg-white md:gap-16 md:flex-row ${className}`
    : "flex flex-col items-center justify-center gap-10 bg-white md:gap-16 md:flex-row";
  const textClasses = "text-20 text-center md:w-9/12 md:text-base md:text-left";
  const logoContainerClasses =
    "flex flex-col items-center justify-center mb-10";
  const logoTextClasses = "text-base text-grey-500 md:text-standard";
  const logoImageClasses = "min-w-[121px] md:min-w-[196px]";

  return (
    <div className={containerClasses}>
      <p className={textClasses}>
        At Apex Digital, we specialize in bringing cutting-edge digital
        transformation to large organizations. Our tailored solutions
        revolutionize your business processes, ensuring efficiency, scalability,
        and future-readiness.
      </p>
      <div className={logoContainerClasses}>
        <p className={logoTextClasses}>Trusted by</p>
        <MarriottLogo className={logoImageClasses} />
      </div>
    </div>
  );
};
