import React, { useEffect, useState } from "react";
import logoAnimation from "../assets/images/logo-animation.gif";
import loadLogo from "../assets/svg/load-logo.svg";

export const Loader = () => {
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const animationDuration = 1000;
  const steps = 100;
  const stepDuration = animationDuration / steps;
  const additionalDelay = 1000;

  useEffect(() => {
    let step = 0;

    const intervalId = setInterval(() => {
      setLoadingPercentage((prevPercentage) =>
        Math.min(prevPercentage + 1, 100)
      );

      step += 1;
      if (step === steps) {
        clearInterval(intervalId);
        setTimeout(() => {
          setIsCompleted(true);
        }, additionalDelay);
      }
    }, stepDuration);

    return () => {
      clearInterval(intervalId);
    };
  }, [additionalDelay]);

  const handleAnimationEnd = () => {
    console.log("Animation completed!");
  };

  return (
    <div
      className={`relative loader${isCompleted ? " completed" : ""}`}
      onTransitionEnd={handleAnimationEnd}
    >
      <img
        src={logoAnimation}
        alt={"logoAnimation"}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-50"
      />
      <div
        className={`progress${isCompleted ? " collapse" : ""}`}
        style={{ width: `${loadingPercentage}%` }}
      />
      <img
        src={loadLogo}
        alt={"loadLogo"}
        className="absolute bottom-10 left-1/2 transform -translate-x-1/2 scale-50"
      />
    </div>
  );
};

export default Loader;
