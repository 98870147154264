import React from "react";

export const ImageWithSign = ({image, sign, name, className}) => {
    return (
        <div className={`flex flex-col ${className || ''}`}>
            <div className="relative max-w-xs h-auto">
                <img className="top-0" src={image} alt={name}/>
                <img className="absolute bottom-0 left-[-20px]" width="70%" src={sign} alt={name + `sign`}/>
            </div>
            <h2 className="text-base md:text-22 text-white">{name}</h2>
        </div>
    );
};
